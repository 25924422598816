import React, { Component } from 'react'
import { BG_Wood, BG_Wood2, Coal_img } from '../../api/constants/ConfigImage'

export default class Coal extends Component {
    constructor(props) {
        super(props)

    }
    render() {
        const { name, detail } = this.props
        console.log("Name:", name)
        console.log("Detail:", detail)
        let BgUrl = BG_Wood2

        return (
            <div id="coal" style={{ backgroundImage: "url(" + BgUrl + ")" }}>
                <div className="container" style={{ marginBottom: "30px" }}>
                    <div className="row text-center">
                        <div className="col-xs-12 col-sm-12 col-md-12 ">
                            <span style={{ textTransform: "uppercase" }}><h1>{name}</h1></span>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        <div className="col-xs-12 col-sm-12 col-md-8">
                            <div className="card">
                                <span className="img-card">
                                    <img src={Coal_img} alt="coal" />
                                </span>
                                <br />
                                <div className="card-content">
                                    {/* <h4 className="card-title">
                                        <span> {item.TypeName} </span>
                                    </h4> */}
                                    {
                                        detail.map((dtl) => {
                                            return <p className="">{dtl}</p>
                                        })
                                    }
                                </div>


                            </div>
                        </div>
                    </div >
                </div>
            </div>
        )
    }
}