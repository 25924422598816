import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import {
    Service_Used_All, Service_Used1, Service_Used2,
    Service_Used3, Service_Used4, Service_Used5, Service_Used6
} from '../../api/constants/ConfigImage'

export default class ServiceUsed extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { name, title, detail } = this.props
        let images = [Service_Used1, Service_Used2, Service_Used3, Service_Used4, Service_Used5, Service_Used6]
        let serviceDetail = []
        let tagImage = []
        detail.map((value, i) => {
            serviceDetail.push(
                <p key={i}><i className="fa fa-check-circle"></i>{"  "}{value}</p>
            )
        })
        images.map((value, i) => {
            tagImage.push(
                <div key={i} className="col-xs-6 col-sm-4 col-md-4">
                    <div className="card">
                        <img className="card-img-top" src={value} alt="Card image cap" />
                    </div>
                </div>
            )
        })
        return (
            <div id="service-used" className="row">
                <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 hidden-xs"></div>
                <div className="col-xs-12 col-sm-5 col-md-5 text-left">
                    <div className="row heading">
                        <p>{name}</p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 hidden-xs"></div>
                        <Fade left>
                            <div className="col-xs-12 col-sm-11 col-md-11 text-left">
                                <p>{title}</p>
                                {serviceDetail}
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 right-panel">
                    <div className="item">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <Fade right>
                                    {tagImage}
                                </Fade>
                            </div>
                        </div>
                        {/* <img src={Service_Used_All} alt="image" /> */}
                    </div>
                </div>
            </div>
        )
    }
}