import React, { Component } from 'react'
import Roll from 'react-reveal/Roll'
import Fade from 'react-reveal/Fade'
import {
    ServiceStep, ServiceStepTh, Service_Step1, Service_Step2, Service_Step3,
    Service_Step4, Service_Step5, Service_Step6
} from '../../api/constants/ConfigImage'

export default class ServiceWorkstep extends Component {
    constructor() {
        super();

    }

    render() {
        const { name, detail } = this.props
        const imageStep = name =="WORK STEP" ? ServiceStep : ServiceStepTh
        let images = [
            Service_Step1, Service_Step2, Service_Step3,
            Service_Step4, Service_Step5, Service_Step6
        ]
        let serviceDetail = []
        detail.map((value, i) => {
            serviceDetail.push(
                <div key={i} className="col-xs-12 col-sm-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <img className="card-img-top" src={images[i]} alt="vivachemistry" />
                            <p className="card-block">{value}</p>
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <div id="service-workstep" className="row">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 heading">
                        <p>{name}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 step-circle">
                        <Roll top>
                            <img src={imageStep} alt="vivachemistry" />
                        </Roll>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-1 col-sm-1 col-md-1 hidden-xs"></div>
                    <div className="col-xs-12 col-sm-10 col-md-10">
                        <Fade bottom>
                            {serviceDetail}
                        </Fade>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 hidden-xs"></div>
                </div>
            </div>
        )
    }
}