import React, { Component } from 'react'
import HeadShake  from 'react-reveal/HeadShake'
import dataServiceTable from '../../api/dataServiceTable.json'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'

export default class ServiceTable extends Component {
    constructor(props) {
        super(props)
    }


    render() {
        const { name, detail } = this.props

        let headTable = [], dataTR = [], dataTD = []
        let tdStyle = ""
        detail.HeadTableName.map((value, i) => {
            headTable.push(
                <th>{value}</th>
            )
        })

        detail.TableDetail.map((value, i) => {
            value.map((values, j) => {
                if (j == 0) {
                    if (i % 2 == 0)
                        tdStyle = "td-green"
                    else
                        tdStyle = "td-green-light"
                } else if (j == 1) {
                    if (i % 2 == 0)
                        tdStyle = "td-yellow"
                    else
                        tdStyle = "td-yellow-light"
                } else if (j == 2) {
                    if (i % 2 == 0)
                        tdStyle = "td-blue"
                    else
                        tdStyle = "td-blue-light"
                } else if (j == 3) {
                    if (i % 2 == 0)
                        tdStyle = "td-red"
                    else
                        tdStyle = "td-red-light"
                } else {
                    tdStyle = ""
                }
                dataTD.push(<td className={tdStyle}>{values}</td>)
            })
            dataTR.push(<tr> {dataTD} </tr>)
            dataTD = []
        })

        return (
            <div id="service-table" className="row hidden-xs">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 heading">
                        <p>{name}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-md-2"></div>
                    <div className="col-sm-2 col-md-8">
                        <HeadShake >
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {headTable}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataTR}
                                </tbody>
                            </table>
                        </HeadShake >
                    </div>
                </div>
            </div>
        )
    }
}