import React, { Component } from 'react'
import Pulse from 'react-reveal/Pulse'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import Gallery from 'react-grid-gallery'
import ControlledCarousel from './ControlledCarousel'
import {
    WH_1, WH_2, WH_3, WH_4, WH_5, WH_6,
    WH_7, WH_8, WH_9, WH_10, WH_11, WH_12,
    WH_13, WH_14, WH_15, WH_16, WH_17, WH_18, WH_19, WH_20
} from '../../api/constants/ConfigImage'

const photos = [
    { src: WH_1, thumbnail: WH_1 },
    { src: WH_2, thumbnail: WH_2 },
    { src: WH_3, thumbnail: WH_3 },
    { src: WH_4, thumbnail: WH_4 },
    { src: WH_5, thumbnail: WH_5 },
    { src: WH_6, thumbnail: WH_6 },
    { src: WH_7, thumbnail: WH_7 },
    { src: WH_8, thumbnail: WH_8 },
    { src: WH_9, thumbnail: WH_9 },
    { src: WH_10, thumbnail: WH_10 },
    { src: WH_11, thumbnail: WH_11 },
    { src: WH_12, thumbnail: WH_12 },
    { src: WH_13, thumbnail: WH_13 },
    { src: WH_14, thumbnail: WH_14 },
    { src: WH_15, thumbnail: WH_15 },
    { src: WH_16, thumbnail: WH_16 },
    { src: WH_17, thumbnail: WH_17 },
    { src: WH_18, thumbnail: WH_18 },
    { src: WH_19, thumbnail: WH_19 },
    { src: WH_20, thumbnail: WH_20 }
]

const photosControlledCarousel = [
    WH_1, WH_2, WH_3, WH_4, WH_5, WH_6,
    WH_7, WH_8, WH_9, WH_10, WH_11, WH_12,
    WH_13, WH_14, WH_15, WH_16, WH_17, WH_18, WH_19, WH_20
]

class WarehouseGallery extends Component {
    render() {
        const content = this.props.content.content;
        const switchLanguage = this.props.switchLanguage

        return (
            <div>
                <Menu data={content.page.Menu} switchLanguage={switchLanguage} />
                <div id="warehouse-gallery">
                    <div className="row">
                        <div className="section-header">
                            <div className="section-title text-center wow fadeInDown"><h2>{content.page.WareHouse.Name}</h2></div>
                        </div>
                    </div>
                    <div className="row text-center row-gallery hidden-xs">
                        <div className="col-xs-12 col-sm-1 col-md-1"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 content-gallery">
                            <Pulse>
                                <Gallery images={photos} rowHeight={160}/>
                            </Pulse>
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1"></div>
                    </div>
                    <div className="row text-center row-gallery hidden-sm hidden-md hidden-lg">
                        <div className="col-xs-12 col-sm-12 col-md-12 content-gallery">
                            <Pulse>
                                <ControlledCarousel imagesGallery={photosControlledCarousel} />
                            </Pulse>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseGallery)