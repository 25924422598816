export const awsUrl = "http://vivawebapi-env.ap-southeast-1.elasticbeanstalk.com/api"
export const type = "/category"
export const googleSiteVerification = "google-site-verification=-aCvjIb_9lP7PRGf_EH4oiBA2ueWYOdjYz7H3oH2QDE"

let backendHost;
const apiVersion = 'v1';
const hostname = window && window.location && window.location.hostname;
if(hostname === 'vivachemistry.firebaseapp.com') {
  backendHost = 'vivachemistry.firebaseapp.com';
} else if(hostname === 'staging.realsite.com') {
  backendHost = 'https://staging.api.realsite.com';
} else if(/^qa/.test(hostname)) {
  backendHost = `https://api.${hostname}`;
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://127.0.0.1:5000';
}

export const API_ROOT = `${backendHost}/api/${apiVersion}/`;
export const DATA_ENDPOINT = `${API_ROOT}/data`