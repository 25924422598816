import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

export default class ServiceCompare extends Component {
    constructor() {
        super();

    }

    render() {
        const { name, detail } = this.props
        let detailEpoxy = [], detailFRP = []
        detail.Epoxy.Detail.map((value, i) => {
            detailEpoxy.push(
                <p><i className="fa fa-check-circle"></i>{"  "}{value}</p>
            )
        })
        detail.FRP.Detail.map((value, i) => {
            detailFRP.push(
                <p><i className="fa fa-check-circle"></i>{"  "}{value}</p>
            )
        })
        return (
            <div id="service-compare" className="row">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 hidden-xs heading">
                        <p>{detail.Epoxy.Name} Vs {detail.FRP.Name}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-1 col-md-1 hidden-xs"></div>
                    <div className="col-xs-12 col-sm-1 col-md-1 hidden-sm hidden-md hidden-lg heading"><p>{detail.Epoxy.Name}</p></div>
                    <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-left left-panel">
                        <Fade left>
                            <p>{detail.Epoxy.Title}</p>
                            {detailEpoxy}
                        </Fade>
                    </div>
                    <div className="col-xs-12 col-sm-1 col-md-1 hidden-sm hidden-md hidden-lg heading"><p>{detail.FRP.Name}</p></div>
                    <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-left right-panel">
                        <Fade right>
                            <p>{detail.FRP.Title}</p>
                            {detailFRP}
                        </Fade>
                    </div>
                </div>
            </div>
        )
    }
}