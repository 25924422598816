import React, { Component } from 'react'
import Reveal from 'react-reveal/Reveal'
import Fade from 'react-reveal/Fade'
import { AttentionIcon, HomeIcon, TruckIcon, GoodIcon, WhyBG } from '../../api/constants/ConfigImage'

export default class ChooseUs extends Component {
    render() {
        var images = [AttentionIcon, HomeIcon, TruckIcon, GoodIcon];
        var choosusDetail = []
        if (this.props.detail != null) {
            this.props.detail.forEach(function (value, i) {
                choosusDetail.push(
                    <Reveal effect="fadeInUp">
                        <div key={i} className="col-xs-12 col-sm-6 col-md-6  wow fadeInUp" data-wow-duration="300ms" data-wow-delay="0ms">
                            <div className="chooseus" >
                                <img src={images[i]} alt="vivachemistry" />
                            </div>
                            <div className="media-body" style={{ display: 'block' }}>
                                <h5>{value}</h5>
                                {/* <p>and deeply realize all the needs of customer</p> */}
                            </div>
                        </div>
                    </Reveal>)
            })
        }

        return (
            // <div id="chooseus" className="section-height">
            //     <div className="container">
            //         <div className="section-header">
            //             <div className="text-center wow fadeInDown"><h3 style={{ color: 'black' }}>{this.props.name}</h3></div>
            //         </div>
            //         <div className="row">
            //             <div className="features">{choosusDetail}</div>
            //         </div>
            //     </div>
            // </div>
            <div id="chooseus" className="section-height">
                <div className="row text-center chooseus-detail">
                    <Fade left>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <img src={WhyBG} style={{ maxWidth: '100%', maxHeight: '500px' }}></img>
                        </div>
                        {/* <div className="col-sm-6 col-md-6 col-lg-6" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="item quality-info" style={{ backgroundImage: "url(" + WhyBG + ")" }}>
                            </div> 
                        </div>*/}
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6" style={{ alignSelf: 'center'}}>
                            <div className="panel-one">
                                {/* <div className="text-center">
                                    <h4 style={{ paddingTop: 50 }}>{this.props.name}</h4>
                                    {tagsDetail}
                                </div> */}
                                <div className="features">{choosusDetail}</div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}