import React, { Component } from 'react'
import SubCategory from './SubCategory'
import { BG_Product, BG_ProductColor } from '../../api/constants/ConfigImage'

export default class Productitems extends Component {
    render() {
        const { category, subCategory, product, color, productId } = this.props
        let BgUrl = color ? BG_ProductColor : BG_Product
        return (
            <div id="product" style={{ backgroundImage: "url(" + BgUrl + ")" }}>
                <div className="row" >
                    <div className="col-xs-12 col-sm-12 col-md-12 product-name">
                        <p>{category}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <SubCategory name={subCategory} products={product} colors={color} productId={productId} />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-2 col-md-2 text-center"></div>
            </div>
        )
    }
}