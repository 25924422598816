import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import ProductList from './ProductList'
import Colors from './Colors'

export default class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productList: [],
            currentList: 1
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(type) {
        this.getProductList(type)

    }

    getProductList(sub_Category_id) {
        let product = []
        if (this.props.products) {
            this.props.products.forEach((value, i) => {
                if (sub_Category_id == value.sub_Category_id) {
                    product.push(value)
                }
            })

            this.setState({ productList: product, currentList: sub_Category_id })
            console.log(product)
        }
    }

    render() {
        const { name, products, colors, productId } = this.props
        const subCategory = []
        if (name) {
            name.map((value, i) => {
                subCategory.push(
                    <div key={i} className="col-xs-12 col-sm-4 col-md-4 text-center">
                        <Button className="btn btn-primary" 
                        style={{
                            backgroundColor: this.state.currentList == i+1 ? '#00BFFF' : 'white',
                            color: this.state.currentList == i+1 ? 'white' : '#00BFFF',
                          }}
                        onClick={() => this.handleClick(i + 1)}>{value.eng_name}</Button>
                    </div>
                )
            })
        }
        //set default first list
        let product = []
        if (products) {
            products.forEach((value, i) => {
                if (value.category_id == productId) {
                    if (value.sub_Category_id == "1") {
                        product.push(value)
                    }
                    else if (value.sub_Category_id == "") {
                        product.push(value)
                    }
                }

            })
console.log(product)
        }
        // if (this.props.products.length > 0) { this.getProductList(1)}
        return (
            <div id="subCategory">
                <div className="row">
                    <div className="col-xs-12 col-sm-2 col-md-2 text-center"></div>
                    {subCategory}
                    <div className="col-xs-12 col-sm-2 col-md-2 text-center"></div>
                </div>
                {
                    colors ? <Colors items={colors} /> :
                        <ProductList products={productId == "1" && this.state.productList.length > 0 ? this.state.productList : product} />
                }

            </div>
        )
    }
}