import axios from 'axios'
import * as myConstClass from '../api/constants/configapi'
import dataCategory1 from '../api/dataCategory1.json'
import dataCategory2 from '../api/dataCategory2.json'
import dataCategory3 from '../api/dataCategory3.json'

export function getProduct(params) {
  //   return function(dispatch){
  //   dispatch({ type: 'FETCH_PRODUCT_REQUEST' })
  //    axios.get(myConstClass.awsUrl + myConstClass.type + "/" + params)
  //    .then((res) => {
  //      dispatch({ type: 'FETCH_PRODUCT_SUCCESS', payload: res.data })
  //    })
  //    .catch(function(error){
  //       dispatch({ type: 'FETCH_PRODUCT_FAILURE', error: error.message })
  //    })
  //  }
  return function (dispatch) {
    if (params == 1) {
      dispatch({ type: 'FETCH_PRODUCT_SUCCESS', payload: dataCategory1 })
    } else if (params == 2) {
      dispatch({ type: 'FETCH_PRODUCT_SUCCESS', payload: dataCategory2 })
    } else if (params == 3) {
      dispatch({ type: 'FETCH_PRODUCT_SUCCESS', payload: dataCategory3 })
    }

  }
}