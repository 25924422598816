import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import '../../css/pricingtable.css'
import ServiceUsed from '../service/ServiceUsed'
import ServiceWorkstep from '../service/ServiceWorkstep'
import ServiceCompare from '../service/ServiceCompare'
import ServiceTable from '../service/ServiceTable'
import { HeadBG_Service } from '../../api/constants/ConfigImage'

class Service extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const content = this.props.content.content;
        const contentService = this.props.content.content.page.Services;
        const switchLanguage = this.props.switchLanguage;
        return (
            <div>
                <Menu data={content.page.Menu} switchLanguage={switchLanguage} />
                <div id="service">
                    <div className="row head-info-height head-info" style={{ backgroundImage: "url(" + HeadBG_Service + ")" }}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-block">
                            <Fade top><p>{contentService.Name}</p></Fade>
                        </div>
                    </div>
                    <div className="row">
                        <ServiceUsed name={contentService.Detail.ServicesUsed.Name} title={contentService.Detail.ServicesUsed.Title} detail={contentService.Detail.ServicesUsed.Detail}/>
                        <ServiceWorkstep name={contentService.Detail.ServicesWorkStep.Name} detail={contentService.Detail.ServicesWorkStep.Detail}/>
                        <ServiceCompare name={contentService.Detail.ServiceCompared.Name} detail={contentService.Detail.ServiceCompared.Detail}/>
                        <ServiceTable name={contentService.Detail.ServiceTable.Name} detail={contentService.Detail.ServiceTable.Detail} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service)