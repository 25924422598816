import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import fetch from 'isomorphic-fetch'
import ProductItem from './ProductItems'
import Wood from './Wood'
import Coal from './Coal'
import { getProduct } from '../../actions/product'

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            category: "",
            subCategory: [],
            product: [],
            color: [],
            loading: true,
            productId: this.props.match.params.id
        }
    }

    componentDidMount() {
        this.loadData(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.setState({ loading: true, productId: nextProps.match.params.id })
            this.loadData(nextProps.match.params.id)
        }

        this.setState({ productId: nextProps.match.params.id })
    }

    loadData(param) {
        this.props.getProduct(param)
        this.setState({ loading: false })
    }

    render() {
        const productId = this.state.productId
        const content = this.props.content.content
        const switchLanguage = this.props.switchLanguage
        const { isFetching, data } = this.props.products
        const woodDetail = content.page.Wood
        const coalDetail = content.page.Coal

        return (
            <div>
                <Menu data={content.page.Menu} switchLanguage={switchLanguage} />
                {
                    isFetching ? <div className="loader"></div> :
                        <div>
                            {
                                productId < 4 ? <ProductItem
                                    category={data.data.eng_name}
                                    subCategory={data.data.subCategory}
                                    product={data.data.product}
                                    color={data.data.color}
                                    productId={productId}
                                />
                                    : ''
                            }
                            {
                                productId == 4 ? <Wood name={woodDetail.Name} detail={woodDetail.Detail} /> : ''
                            }
                            {
                                productId == 5 ? <Coal name={coalDetail.Name} detail={coalDetail.Detail} /> : ''
                            }
                        </div>

                }

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        products: state.products,
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProduct: (params) => {
            return dispatch(getProduct(params))
        },
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)
