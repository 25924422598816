import React, { Component } from 'react'

export default class Colors extends Component {
    constructor(props) {
        super(props)

    }
    render() {
        const { items } = this.props
        const colorlist = []
        if (items) {
            items.map((value, i) => {
                colorlist.push(
                    <div key={i} className="col-xs-6 col-sm-3 col-md-3">
                        <div className="card">
                            {
                                value.hexcode != "" ? <div className="card-item" style={{ background: value.hexcode }}></div>
                                : <div className="card-item"><div className="card-empty"><p>No Image</p></div></div>
                            }
                            <div className="card-body">
                                <h6 className="card-title">{value.id} : {value.name}</h6>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div id="color" className="row text-center">
                <div className="col-xs-12 col-sm-1 col-md-1 text-center hidden-xs"></div>
                <div className="col-xs-12 col-sm-10 col-md-10 text-center">
                    {colorlist}
                </div>
            </div>
        )
    }
}