import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap';
import { VivaLogo, IsoLogo } from '../../api/constants/ConfigImage'

export default class Contact extends Component {
    render() {
        const { name, companyName, address, tel, fax, email } = this.props
        return (
            <div id="contactus" style={{ paddingBottom: '50px', background: '#444444' }} >
                <div className="container" >
                    <div className="section-header">
                        {/* <div className="section-title text-center wow fadeInDown"><h4 style={{ color: 'white' }}>{name}</h4></div> */}
                    </div>
                    <Grid>
                        <Row>
                            <Col xs={12} sm={2} md={2}>
                                <img src={IsoLogo} alt="vivachemistry"/>
                            </Col>
                            <Col xs={12} sm={2} md={2}>
                                <a href="/"><img src={VivaLogo} alt="vivachemistry" /></a>
                            </Col>
                            <Col xs={12} sm={3} md={3}>
                                <p className="text-center wow fadeInDown text-white">{companyName}</p>
                                <div className="address">
                                    <p className="text-white">Follow Us</p>
                                    <p><a href="http://web.facebook.com/vivachemistry"><i className="fa fa-facebook"></i>{' '}@vivachemistry</a>  </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={5} md={5}>
                                <p className="text-left wow fadeInDown text-white">Address: {address}</p>
                                <p className="text-left wow fadeInDown text-white">Tel: {tel}, Fax: {fax}</p>
                                <p className="text-left wow fadeInDown text-white">Email: {email}</p>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        );
    }
}