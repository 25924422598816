
const initialState = {
    data: {
        data: {
            subCategory: [],
            product: [],
            color: [],
            id: 1,
            thai_name: '',
            eng_name: '',
            wood: [],
            coal: []
        }
    }
}


export const products = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT_REQUEST':
            return {
                ...state,
                isFetching: true
            }
            break;
        case 'FETCH_PRODUCT_SUCCESS':
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
            break;
        case 'FETCH_PRODUCT_FAILURE':
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
            break;
    }
    return state
}

