import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import '../../css/warehouse.css'
import { Grid, Row, Col } from 'react-bootstrap';
import ControlledCarousel from './ControlledCarousel'
import { Link } from 'react-router-dom'
import { WH_11, WH_18, WH_21, WH_F } from '../../api/constants/ConfigImage'

export default class WareHouse extends Component {
    render() {
        let images = [WH_11, WH_18, WH_21];
        let tagImage = [];
        images.forEach((value, i) => {
            tagImage.push(
                <Col xs={12} sm={4} md={4} xsHidden key={i}>
                    <div className="warehouse-item" style={{ backgroundImage: "url(" + value + ")" }}></div>
                </Col>)

        });

        const { name, detail } = this.props

        return (
            <div id="warehouse" className="section-warehouse-height warehouse-bg">
                <div className="row text-center">
                    <Fade Left>
                        <div className="col-sm-6 col-md-6 col-lg-6" style={{ padding: '0'}}>
                            <img src={WH_F} style={{ maxWidth: '100%', maxHeight: '500px' }}></img>
                        </div>
                        {/* <div className="col-sm-6 col-md-6 col-lg-6" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="item quality-info" style={{ backgroundImage: "url(" + WhyBG + ")" }}>
                            </div> 
                        </div>*/}
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6">
                            <div className="panel-one">
                                {/* <div className="text-center">
                                    <h4 style={{ paddingTop: 50 }}>{this.props.name}</h4>
                                    {tagsDetail}
                                </div> */}
                                <div className="features">{}</div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/* <div className="container">
                    <Grid>
                        <Row>
                            <div className="section-header text-center">
                                <div className="section-title text-center wow fadeInDown"><h3 style={{ color: 'black' }}>{name}</h3></div>
                                <p className="text-center wow fadeInDown"></p>
                            </div>
                        </Row>
                        <Row >
                            <Col xs={12} sm={12} md={12} smHidden mdHidden lgHidden>
                                <Fade bottom>
                                    <ControlledCarousel imagesGallery={images} />
                                </Fade>
                            </Col>
                        </Row>
                        <Row>{tagImage}</Row>
                        <Row className="text-center">
                            <div className="warehouse-button">
                                <Link className="btn btn-primary" to="/warehouse">{name == "คลังสินค้า" ? "รายละเอียด" : "View More"}</Link>
                            </div>
                        </Row>
                    </Grid>
                </div> */}
            </div>
        );
    }
}