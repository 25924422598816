import React, { Component } from 'react';
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import Info from '../home/Info'
import DetailInfo from '../home/DetailInfo'
import OurClient from '../home/OurClient'
import QualityProduct from '../home/QualityProduct'
import WareHouse from '../home/WareHouse'
import ChooseUs from '../home/ChooseUs'
import Contact from '../contact/Contact'
import fetch from 'isomorphic-fetch'
import Wood from '../product/Wood'
import Coal from '../product/Coal'

class Home extends Component {
    render() {
        const content = this.props.content.content;
        const switchLanguage = this.props.switchLanguage;

        if (content) {
            return (
                <div id="home" className="homepage">
                    <Menu data={content.page.Menu} switchLanguage={switchLanguage} />
                    <Info name={content.page.Info.Name} detail={content.page.Info.Detail} />
                    <DetailInfo name={content.page.DetailInfo.Name} detail={content.page.DetailInfo.Detail} />
                    <ChooseUs name={content.page.ChooseUs.Name} detail={content.page.ChooseUs.Detail} />
                    <WareHouse name={content.page.WareHouse.Name} detail={content.page.WareHouse.Detail}/>
                    <QualityProduct name={content.page.QualityProducts.Name} detail={content.page.QualityProducts.Detail} />
                   
                    <OurClient name={content.page.OurClient.Name} detail={content.page.OurClient.Detail}/>
                   
                    <Contact name={content.page.Contact.Name}
                        companyName={content.page.Contact.Detail.CompanyName}
                        address={content.page.Contact.Detail ? content.page.Contact.Detail.Address : ""}
                        tel={content.page.Contact.Detail ? content.page.Contact.Detail.Tel : ""}
                        fax={content.page.Contact.Detail ? content.page.Contact.Detail.Fax : ""}
                        email={content.page.Contact.Detail ? content.page.Contact.Detail.Email : ""}
                    />
                </div>
            );
        } else {
            return <div></div>
        }

    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)