import React, { Component } from 'react'
import '../../css/menu.css'
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { APP_Name } from '../../api/constants/ConfigImage'

export default class Menu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const data = this.props.data;
    console.log("contact", data)
    let switchLanguage = this.props.switchLanguage;

    return (
      <header id="menu" className="navbar-fixed-top">
        <div className="hidden-md hidden-lg">
          <div className="row">
            <div className="text-center head-contact">
              <i className="text-success fa fa-phone fa-w-16"></i><label>&nbsp;(66) 3835-1950 &emsp;</label>
              <i className="text-primary fa fa-map-marker fa-w-12"></i> <label>&nbsp;{data.province}&emsp;</label>
            </div>
          </div>
          <div className="row">
            <Navbar fluid collapseOnSelect>
              <Navbar.Header>
                <Navbar.Brand>
                  <a href="/"> <span className="name-blue">VIVA</span><span className="name-hard-blue">CHEMISTRY</span></a>
                </Navbar.Brand>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>
                <Nav pullRight>
                  {/* <IndexLinkContainer to="/" activeClassName="active-link">
                    <NavItem>{data.home}</NavItem>
                  </IndexLinkContainer> */}
                  <LinkContainer to="/aboutus" activeClassName="active-link">
                    <NavItem>{data.aboutus}</NavItem>
                  </LinkContainer>
                  {/* <LinkContainer to="/service" activeClassName="active-link">
                    <NavItem>{data.services}</NavItem>
                  </LinkContainer> */}
                  <LinkContainer to="/warehouse" activeClassName="active-link">
                    <NavItem>{data.warehouse}</NavItem>
                  </LinkContainer>
                  <NavDropdown eventKey={4} title={data.products} id="basic-nav-dropdown">
                    <LinkContainer to="/product/1" activeClassName="active-link"><MenuItem eventKey={4.1}>Solvents</MenuItem></LinkContainer>
                    <LinkContainer to="/product/2" activeClassName="active-link"><MenuItem eventKey={4.2}>Basic Chemicals</MenuItem></LinkContainer>
                    {/* <LinkContainer to="/product/3" activeClassName="active-link"><MenuItem eventKey={4.3}>Color</MenuItem></LinkContainer>
                    <LinkContainer to="/product/4" activeClassName="active-link"><MenuItem eventKey={4.4}>{data.wood}</MenuItem></LinkContainer>
                    <LinkContainer to="/product/5" activeClassName="active-link"><MenuItem eventKey={4.5}>{data.coal}</MenuItem></LinkContainer> */}
                  </NavDropdown>
                  <NavItem eventKey={5} href="/#contactus">{data.contact}</NavItem>
                  <LinkContainer to="/service" activeClassName="active-link">
                    <NavItem><Link id="lang-th" to="/" activeStyle={{ background: 'transparent', color: '#00BFFF' }} onClick={switchLanguage.bind(this, 'en')}>EN</Link>
                      |
                          <Link id="lang-en" to="/" activeStyle={{ background: 'transparent', color: '#00BFFF' }} onClick={switchLanguage.bind(this, 'th')}>TH</Link></NavItem>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div className="hidden-xs hidden-sm">
          <div className="row show-grid">
            <div className="col-sm-4 col-md-4 col-lg-4 name">
              <div id="head-text">
                <a href="/"><span className="name-blue">VIVA</span><span className="name-hard-blue">CHEMISTRY</span></a>
                {/* <img src={APP_Name} alt="name" /> */}
              </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8 page-menu">
              <div className="row">
                <div className="col-sm-offset-6 col-md-offset-6">
                  <div className="text-center head-contact">
                    <i className="text-success fa fa-phone fa-w-16"></i><label>&nbsp;(66) 3835-1950 &emsp;</label>
                    <i className="text-primary fa fa-map-marker fa-w-12"></i> <label>&nbsp;{data.province}&emsp;</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                      <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                      <Nav pullRight>
                        {/* <IndexLinkContainer to="/" activeClassName="active-link">
                          <NavItem>{data.home}</NavItem>
                        </IndexLinkContainer> */}
                        <LinkContainer to="/aboutus" activeClassName="active-link">
                          <NavItem>{data.aboutus}</NavItem>
                        </LinkContainer>
                        {/* <LinkContainer to="/service" activeClassName="active-link">
                          <NavItem>{data.services}</NavItem>
                        </LinkContainer> */}
                        <LinkContainer to="/warehouse" activeClassName="active-link">
                          <NavItem>{data.warehouse}</NavItem>
                        </LinkContainer>
                        <NavDropdown eventKey={4} title={data.products} id="basic-nav-dropdown">
                          <LinkContainer to="/product/1" activeClassName="active-link"><MenuItem eventKey={4.1}>Solvents</MenuItem></LinkContainer>
                          <LinkContainer to="/product/2" activeClassName="active-link"><MenuItem eventKey={4.2}>Basic Chemicals</MenuItem></LinkContainer>
                          {/* <LinkContainer to="/product/3" activeClassName="active-link"><MenuItem eventKey={4.3}>Color</MenuItem></LinkContainer>
                          <LinkContainer to="/product/4" activeClassName="active-link"><MenuItem eventKey={4.4}>{data.wood}</MenuItem></LinkContainer>
                          <LinkContainer to="/product/5" activeClassName="active-link"><MenuItem eventKey={4.5}>{data.coal}</MenuItem></LinkContainer> */}
                        </NavDropdown>
                        <NavItem eventKey={5} href="/#contactus">{data.contact}</NavItem>
                        <LinkContainer to="/service" activeClassName="active-link">
                          <NavItem>
                            <Link id="lang-th" to="/" activeStyle={{ background: 'transparent', color: '#00BFFF' }} onClick={switchLanguage.bind(this, 'en')}>EN </Link>
                            |
                          <Link id="lang-en" to="/" activeStyle={{ background: 'transparent', color: '#00BFFF' }} onClick={switchLanguage.bind(this, 'th')}> TH</Link></NavItem>
                        </LinkContainer>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >
    );
  }
}