//APP
export const APP_Name = process.env.PUBLIC_URL + '/images/name.png'
export const QRCode = process.env.PUBLIC_URL + '/images/qrcode.jpg'
export const VivaOfficeImage = process.env.PUBLIC_URL + '/images/office_viva.jpg'
export const VivaLogo = process.env.PUBLIC_URL + '/images/logo_viva_full.png'
export const IsoLogo = process.env.PUBLIC_URL + '/images/iso2015.png'
//About Us
export const HeadBG_AboutUs = process.env.PUBLIC_URL + '/images/aboutus.jpg'
export const Head_AboutUs = process.env.PUBLIC_URL + '/images/aboutus006.jpg'

//Home Section1_Info
export const HeadBG_Info = process.env.PUBLIC_URL +  '/images/bg4.jpg'

//Home Section2_DetailInfo
export const ColumnBG_DetailInfo = process.env.PUBLIC_URL + '/images/office_viva.jpg'

//Home Section3_Choose Us
export const AttentionIcon = process.env.PUBLIC_URL +  '/images/icon/icontest_55.png'
export const HomeIcon = process.env.PUBLIC_URL +  '/images/icon/icontest_33.png'
export const TruckIcon = process.env.PUBLIC_URL +  '/images/icon/icontest_22.png'
export const GoodIcon = process.env.PUBLIC_URL +  '/images/icon/icontest_44.png'
export const WhyBG = process.env.PUBLIC_URL +  '/images/whychooseus.png'

//HOme Section4_Quality Product
export const ColumnBG_Quality = process.env.PUBLIC_URL + '/images/warehouse/warehouse017.jpg'

//Home Section5_Our Client
export const ToyotaLogo = process.env.PUBLIC_URL + '/images/logoClient/toyota-logo.jpg'
export const HondaLogo = process.env.PUBLIC_URL + '/images/logoClient/honda-logo.jpg'
export const BesLogo = process.env.PUBLIC_URL + '/images/logoClient/bes-logo.jpg'
export const ScgLogo = process.env.PUBLIC_URL + '/images/logoClient/scg-logo.jpg'
export const GfptLogo = process.env.PUBLIC_URL + '/images/logoClient/gfpt-logo.jpg'
export const IndoramaLogo = process.env.PUBLIC_URL + '/images/logoClient/indorama-logo.jpg'
export const AgcLogo = process.env.PUBLIC_URL + '/images/logoClient/agc-logo.jpg'
export const MichelinLogo = process.env.PUBLIC_URL + '/images/logoClient/michelin-logo.jpg'
export const PlcLogo = process.env.PUBLIC_URL + '/images/logoClient/plc-logo.jpg'
export const SaksriLogo = process.env.PUBLIC_URL + '/images/logoClient/saksri-logo.jpg'
export const SapthipLogo = process.env.PUBLIC_URL + '/images/logoClient/sapthip-logo.jpg'
export const SiamcementLogo = process.env.PUBLIC_URL + '/images/logoClient/siamcement-logo.jpg'
export const DoubleALogo = process.env.PUBLIC_URL + '/images/logoClient/doublea-logo.jpg'
export const AccLogo = process.env.PUBLIC_URL + '/images/logoClient/acc-logo.jpg'
export const NssptLogo = process.env.PUBLIC_URL + '/images/logoClient/nsspt-logo.jpg'
export const PensiriLogo = process.env.PUBLIC_URL + '/images/logoClient/pensiri2-logo.jpg'
export const ZeonLogo = process.env.PUBLIC_URL + '/images/logoClient/zeon-logo.jpg'
export const AsmLogo = process.env.PUBLIC_URL + '/images/logoClient/asm-logo.gif'
export const ShinLogo = process.env.PUBLIC_URL + '/images/logoClient/shin-logo.jpg'
export const AdityaLogo = process.env.PUBLIC_URL + '/images/logoClient/aditya-logo.jpg'

//Warehouse Gallery
// const images = process.env.PUBLIC_URL + '/images/warehouse'
export const WH_1 = process.env.PUBLIC_URL + '/images/warehouse/warehouse001.jpg'
export const WH_2 = process.env.PUBLIC_URL + '/images/warehouse/warehouse002.jpg'
export const WH_3 = process.env.PUBLIC_URL + '/images/warehouse/warehouse003.jpg'
export const WH_4 = process.env.PUBLIC_URL + '/images/warehouse/warehouse004.jpg'
export const WH_5 = process.env.PUBLIC_URL + '/images/warehouse/warehouse005.jpg'
export const WH_6 = process.env.PUBLIC_URL + '/images/warehouse/warehouse006.jpg'
export const WH_7 = process.env.PUBLIC_URL + '/images/warehouse/warehouse007.jpg'
export const WH_8 = process.env.PUBLIC_URL + '/images/warehouse/warehouse008.jpg'
export const WH_9 = process.env.PUBLIC_URL + '/images/warehouse/warehouse009.jpg'
export const WH_10 = process.env.PUBLIC_URL + '/images/warehouse/warehouse010.jpg'
export const WH_11 = process.env.PUBLIC_URL + '/images/warehouse/warehouse011.jpg'
export const WH_12 = process.env.PUBLIC_URL + '/images/warehouse/warehouse012.jpg'
export const WH_13 = process.env.PUBLIC_URL + '/images/warehouse/warehouse013.jpg'
export const WH_14 = process.env.PUBLIC_URL + '/images/warehouse/warehouse014.jpg'
export const WH_15 = process.env.PUBLIC_URL + '/images/warehouse/warehouse015.jpg'
export const WH_16 = process.env.PUBLIC_URL + '/images/warehouse/warehouse016.jpg'
export const WH_17 = process.env.PUBLIC_URL + '/images/warehouse/warehouse017.jpg'
export const WH_18 = process.env.PUBLIC_URL + '/images/warehouse/warehouse018.jpg'
export const WH_19 = process.env.PUBLIC_URL + '/images/warehouse/warehouse019.jpg'
export const WH_20 = process.env.PUBLIC_URL + '/images/warehouse/warehouse020.jpg'
export const WH_21 = process.env.PUBLIC_URL + '/images/warehouse/warehouse021.jpg'
export const WH_F = process.env.PUBLIC_URL + '/images/fwarehouse2.png'
//Product
export const BG_Product = process.env.PUBLIC_URL +  '/images/bg3.jpg'
export const BG_ProductColor = process.env.PUBLIC_URL +  '/images/bg_color.jpg'

//Service
export const HeadBG_Service = process.env.PUBLIC_URL +  '/images/service-bg.jpg'
export const ColumnBG_ServiceUsed = process.env.PUBLIC_URL +  '/images/work_6.jpg'
export const ServiceStep = process.env.PUBLIC_URL +  '/images/service-circle-step.png'
export const ServiceStepTh = process.env.PUBLIC_URL +  '/images/service-circle-step-th.png'
export const ServiceStepItem = process.env.PUBLIC_URL +  '/images/work_6.jpg'

//Service-Used
export const Service_Used_All = process.env.PUBLIC_URL +  '/images/service/used-all.png'
export const Service_Used1 = process.env.PUBLIC_URL +  '/images/service/used1.jpg'
export const Service_Used2 = process.env.PUBLIC_URL +  '/images/service/used2.jpg'
export const Service_Used3 = process.env.PUBLIC_URL +  '/images/service/used3.jpg'
export const Service_Used4 = process.env.PUBLIC_URL +  '/images/service/used4.jpg'
export const Service_Used5 = process.env.PUBLIC_URL +  '/images/service/used5.jpg'
export const Service_Used6 = process.env.PUBLIC_URL +  '/images/service/used6.jpg'

//Service-work step
export const Service_Step1 = process.env.PUBLIC_URL +  '/images/service/step1.jpg'
export const Service_Step2 = process.env.PUBLIC_URL +  '/images/service/step2.jpg'
export const Service_Step3 = process.env.PUBLIC_URL +  '/images/service/step3.jpg'
export const Service_Step4 = process.env.PUBLIC_URL +  '/images/service/step4.jpg'
export const Service_Step5 = process.env.PUBLIC_URL +  '/images/service/step5.jpg'
export const Service_Step6 = process.env.PUBLIC_URL +  '/images/service/step6.jpg'

//Wood
export const BG_Wood = process.env.PUBLIC_URL +  '/images/bg_forest.png'
export const BG_Wood2 = process.env.PUBLIC_URL +  '/images/forest2.jpg'
export const Wood_Ship = process.env.PUBLIC_URL +  '/images/products/woodship.png'
export const Shell_Indo = process.env.PUBLIC_URL +  '/images/products/shellIndo.png'
export const Shell_Thai = process.env.PUBLIC_URL +  '/images/products/shellthai.png'

export const Coal_img = process.env.PUBLIC_URL +  '/images/products/coal.jpg'