import React, { Component } from 'react'
import { BG_Wood, Wood_Ship, Shell_Indo, Shell_Thai } from '../../api/constants/ConfigImage'

export default class Wood extends Component {
    constructor(props) {
        super(props)

    }
    render() {
        const { name, detail } = this.props
        const imagesProduct = [Wood_Ship, Shell_Indo, Shell_Thai]
        let BgUrl = BG_Wood
        const tagDetail1 = [], tagDetail2 = []


        return (
            <div id="wood" style={{ backgroundImage: "url(" + BgUrl + ")" }}>
                <div className="" style={{ margin: "30px" }}>
                    <div className="row text-center">
                        <div className="col-xs-12 col-sm-12 col-md-12 ">
                            <span style={{ textTransform: "uppercase" }}><h1>{name}</h1></span>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            {
                                detail.map((item, index) => {
                                    return <div className="col-xs-12 col-sm-12 col-md-4">
                                        <div className="card">
                                            <span className="img-card">
                                                <img src={imagesProduct[index]} alt="wood" />
                                            </span>
                                            <br />
                                            <div className="card-content">
                                                <h4 className="card-title">
                                                    <span> {item.TypeName} </span>
                                                </h4>
                                                {
                                                    item.Description.map((dtl) => {
                                                        return <p className="">{dtl}</p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}