import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { HeadBG_Info } from '../../api/constants/ConfigImage'

export default class Info extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.map((item, index) => {
                tagsDetail.push(<p key={index}>{item}</p>)
            })
        }
        return (
            <div id="main">
                <div className="row head-info" style={{ backgroundImage: "linear-gradient(180deg, rgba(32, 160, 255, 0.12), rgba(2, 1, 1, 0.48) 70%),url(" + HeadBG_Info + ")" }}>
                    <div className="col-sm-12 text-center">
                        <div className="carousel-content text-block">
                            <Fade top>
                                {tagsDetail}
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}