import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/bootstrap.min.css'
import './css/font-awesome.min.css'
import './css/animate.min.css'
import './css/owl.carousel.css'
import './css/owl.transitions.css'
import './css/main.css'
import App from './components/app/App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
