import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import '../../css/timeline.css'
import '../../css/timeline2.css'
import { HeadBG_AboutUs, Head_AboutUs } from '../../api/constants/ConfigImage'

class AboutUs extends Component {
    render() {
        const content = this.props.content.content;
        const switchLanguage = this.props.switchLanguage;

        let tagDetail = [];
        if (content.page.AboutUs.Detail != null) {
            content.page.AboutUs.Detail.forEach((value, i) => {
                tagDetail.push(
                    <Fade left>
                        <div className={value.PeriodClassName}>
                            <div className="shape">{value.Period}
                                {/* <h3>{value.Period}</h3> */}
                            </div>
                            <p className="steps-description">{value.PeriodDetail}</p>
                        </div>
                    </Fade>
                )
            })
        }
        return (
            <div>
                <Menu data={content.page.Menu} switchLanguage={switchLanguage} />
                <div id="aboutus">
                    <div className="row head-info-height head-info" style={{ backgroundImage: "url(" + Head_AboutUs + ")" }}>
                    </div>
                    <br />
                    <dic className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 text-center"></div>
                        <Fade right>
                            <div className="col-xs-12 col-sm-8 col-md-8 text-center">
                                <div className="section-header">
                                    <div className="section-title text-center wow fadeInDown"><h3>{content.page.Policy.Name}</h3></div>
                                </div>
                                <p className="h3">{content.page.Contact.Detail.CompanyName}</p>
                                <p className="h3">{content.page.Policy.Detail}</p>
                            </div>
                        </Fade>
                    </dic>
                    <br />
                    <div className="row row-step">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <div id="Steps" className="steps-section">
                                <div className="steps-timeline">
                                    {tagDetail}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)