import React, { Component } from 'react';
import Fade from 'react-reveal/Fade'
import { ColumnBG_Quality } from '../../api/constants/ConfigImage'

export default class QualityProduct extends Component {
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.forEach(function (value, i) {
                tagsDetail.push(<p key={i} className="text-justify">{value}</p>)
            })
        }
        return (
            <div id="qualityproduct" className="section-height quality-bg">
                <div className="row text-center">
                    <Fade left>
                        <div className="col-sm-6">
                            <div className="panel-one">
                                <div className="text-center">
                                    <h4 style={{ paddingTop: 50 }}>{this.props.name}</h4>
                                    <br/>
                                    {tagsDetail}
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade Right>
                        <div className="col-sm-6" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="item quality-info" style={{ backgroundImage: "url(" + ColumnBG_Quality + ")" }}>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}