import React, { Component } from 'react'
import Reveal from 'react-reveal/Reveal'
import {
  ToyotaLogo, HondaLogo, BesLogo, ScgLogo, GfptLogo, IndoramaLogo,
  AgcLogo, MichelinLogo, PlcLogo, SaksriLogo, SapthipLogo, SiamcementLogo,
  DoubleALogo, AccLogo, NssptLogo, PensiriLogo, ZeonLogo, AsmLogo, ShinLogo,AdityaLogo
} from '../../api/constants/ConfigImage'

export default class OurClient extends Component {
  render() {
    let images = [
      BesLogo, ScgLogo, GfptLogo, IndoramaLogo,
      AgcLogo, MichelinLogo, PlcLogo, SaksriLogo, SapthipLogo, SiamcementLogo,
      DoubleALogo, AccLogo, NssptLogo, PensiriLogo, ZeonLogo, AsmLogo, ShinLogo,AdityaLogo
    ];
    let alt = [
      "Bangkok Hospital", "SCG Chemicals Public Company", "GFPT Public Co. Ltd.", "Indorama Ventures PCL",
      "AGC Chemicals (Thailand) Co., Ltd.", "Michelin Manufacturing company", "Pimai Salt Co. Ltd.", 
      "Saksri Industry Co. Ltd.", "Sapthip Green Energy Co. Ltd.", "Siam City Cement Public Co. Ltd.",
      "Double A (1991) Public Co., Ltd.", "Asian Chemical Co. Ltd.", "NIPPON STEEL Steel Processing (Thailand) Co. Ltd.", 
      "Pensiri Steel Industries Co. Ltd.", "Zeon Chemicals", "Asia Silicones Monomer Limited", 
      "Shin-Etsu Chemical Co., Ltd.","Aditya Birla Chemicals"
    ];
    let tagImage = [];
    images.forEach((value, i) => {
      tagImage.push(
        <Reveal effect="fadeInUp">
          <div className="col-xs-6 col-sm-2 col-md-2">
            <div className="ourclient" style={{ margin: '10px' }}>
              <img src={value} alt={alt[i]} title={alt[i]}/>
            </div>
          </div>
        </Reveal>)
    });

    const { name, detail } = this.props

    return (

      <div id="testimonial" className="ourclient-height ourclient-bg">
        <div className="container">
          <div className="section-header">
            {/* <div className="section-title text-center wow fadeInDown"><h3 style={{ color: 'black' }}>{name}</h3></div> */}
          </div>
          <div className="row">
            {tagImage}
          </div>
        </div>
      </div>
    );
  }
}