import React, { Component } from 'react';
import Fade from 'react-reveal/Fade'
import { ColumnBG_DetailInfo } from '../../api/constants/ConfigImage'

export default class DetailInfo extends Component {
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.map((item, index) => {
                tagsDetail.push(<p key={index} className="text-justify">{item}</p>)
            })
        }

        return (
            <div id="detailinfo" className="section-height quality-bg">
                <div className="row text-center">
                    <Fade left>
                        <div className="col-sm-6 col-md-6 col-lg-6" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="item quality-info" style={{ backgroundImage: "url(" + ColumnBG_DetailInfo + ")" }}>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6">
                            <div className="panel-one">
                                <div className="text-center">
                                    <h4 style={{ paddingTop: 50 }}>{this.props.name}</h4>
                                    <br/>
                                    <p>{tagsDetail}</p>
                                    
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}