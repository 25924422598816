import * as action_types from './../api/constants/action-types'

const contents = require('./../api/data.json');
let api = {
  getContent(language = 'en') {
    return contents.filter(obj => obj.lang === language)[0];
  }
};
const initialState = {
  content: api.getContent()
}

export const content = function (state = initialState, action) {
  switch (action.type) {
    case action_types.SWITCH_LANGUAGE:
      return {
        content: api.getContent(action.language)
      };
    default:
      return state;
  }
};

