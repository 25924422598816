import React, { Component } from 'react'
import BootstrapTable, { TableHeaderColumn } from 'react-bootstrap-table-next'

export default class ProductList extends Component {
    constructor(props) {
        super(props)

    }
    render() {
        let style = { backgroundColor: "rgb(16,69,120)", color: "#fff", textAlign: "center" }
        const textStyle = "center"
        let columns = [
            {
                dataField: 'eng_name',
                text: 'Product Name',
                // headerAlign: textStyle,
                headerStyle: style
            },
            {
                dataField: 'volume',
                text: 'Volume',
                // headerAlign: textStyle,
                headerStyle: style
            },
            {
                dataField: 'package',
                text: 'Product Package',
                // headerAlign: textStyle,
                headerStyle: style
            },
            {
                dataField: 'origin',
                text: 'Origin',
                // headerAlign: textStyle,
                headerStyle: style
            }];
            
        return (
            <div id="productList" className="row text-center">
                <div className="col-xs-12 col-sm-2 col-md-2 text-center hidden-xs hidden-sm"></div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                    <BootstrapTable
                        keyField='id'
                        data={this.props.products}
                        columns={columns}
                        striped
                        condensed
                        bordered={false}
                    />
                </div>
            </div>
        )
    }
}